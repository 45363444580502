import { Col, Form, Input, Row } from 'antd';
import B2becCustomSelect from 'components/B2becCustomSelect';
import useDeviceDetect from 'hooks/useDeviceDetect';
import useRegion from 'hooks/useRegion';
import { ADDRESS_SCHEME_TYPES } from 'libs/constants/configs';
import { FIELDS_MAX_LENGTH } from 'libs/constants/formValidateConfig';
import { validateZipCode } from 'libs/utils/validatorUtils';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  selectAddressScheme,
  selectShouldHideHouseNumberEntryField,
} from 'store/selectors/configSelector';

const { TextArea } = Input;

const B2becAddressForm = ({ id: propName, disabled }) => {
  const { t } = useTranslation();
  const { isLargeScreen } = useDeviceDetect();

  const { isShowRegion, regions } = useRegion();

  const shouldHideHouseNumber = useSelector(
    selectShouldHideHouseNumberEntryField
  );
  const addressScheme = useSelector(selectAddressScheme);
  const isUSAAddressScheme = addressScheme === ADDRESS_SCHEME_TYPES.USA;

  const mergedName = useCallback(
    (name) => {
      if (propName !== undefined) {
        return [propName, name];
      }

      return name;
    },
    [propName]
  );

  return (
    <>
      <Form.Item
        name={mergedName('name')}
        label={t('form.label.name')}
        className="custom-form-item"
        rules={[
          { required: true },
          { type: 'string', max: FIELDS_MAX_LENGTH.name },
        ]}
      >
        <Input placeholder={t('form.placeHolder.name')} disabled={disabled} />
      </Form.Item>

      <Form.Item
        name={mergedName('name2')}
        label={t('form.label.extraText')}
        className="custom-form-item"
      >
        <TextArea placeholder={t('form.placeHolder.extraText')} />
      </Form.Item>

      <Row gutter={24}>
        <Col flex="auto">
          <Form.Item
            name={mergedName('street')}
            label={t('form.label.street')}
            className="custom-form-item"
            rules={[
              { required: true },
              { type: 'string', max: FIELDS_MAX_LENGTH.street },
            ]}
          >
            <Input
              placeholder={t('form.placeHolder.street')}
              disabled={disabled}
            />
          </Form.Item>
        </Col>
        {!shouldHideHouseNumber && (
          <Col span={24} lg={8}>
            <Form.Item
              name={mergedName('houseNumber')}
              label={t('form.label.houseNumber')}
              className="custom-form-item"
              rules={[
                { required: true },
                { type: 'string', max: FIELDS_MAX_LENGTH.houseNumber },
              ]}
            >
              <Input
                placeholder={t('form.placeHolder.houseNumber')}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Form.Item
        name={mergedName('street4')}
        label={t('form.label.street4')}
        className="custom-form-item"
        rules={[{ type: 'string', max: FIELDS_MAX_LENGTH.street }]}
      >
        <Input
          placeholder={t('form.placeHolder.street4')}
          disabled={disabled}
        />
      </Form.Item>
      <Row gutter={24}>
        <Col span={24} lg={{ span: 8 }} order={isUSAAddressScheme ? 2 : 0}>
          <Form.Item
            name={mergedName('zip')}
            label={t('form.label.zip')}
            className="custom-form-item"
            rules={[
              { required: true },
              ({ getFieldValue }) => {
                const country = getFieldValue(mergedName('country'));
                return validateZipCode(country);
              },
            ]}
          >
            <Input
              placeholder={t('form.placeHolder.zip')}
              disabled={disabled}
            />
          </Form.Item>
        </Col>
        {isShowRegion && (
          <Col span={24} lg={{ span: 8 }} order={1}>
            <Form.Item
              name={mergedName('region')}
              label={t('form.label.region')}
              className="custom-form-item"
              rules={[{ required: true }]}
            >
              <B2becCustomSelect
                placeholder={t('form.placeHolder.region')}
                disabled={disabled}
                options={regions}
              />
            </Form.Item>
          </Col>
        )}
        <Col
          span={24}
          lg={{ span: isShowRegion ? 8 : 16 }}
          order={isUSAAddressScheme ? 0 : 2}
        >
          <Form.Item
            name={mergedName('city')}
            label={t('form.label.city')}
            className="custom-form-item"
            rules={[
              { required: true },
              { type: 'string', max: FIELDS_MAX_LENGTH.city },
            ]}
          >
            <Input
              placeholder={t('form.placeHolder.city')}
              disabled={disabled}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name={mergedName('country')}
        label={t('form.label.country')}
        className="custom-form-item custom-form-item--disabled"
        style={{ width: isLargeScreen ? 320 : 'unset' }}
      >
        <Input disabled />
      </Form.Item>
    </>
  );
};

B2becAddressForm.defaultProps = {
  id: undefined,
  disabled: undefined,
};

B2becAddressForm.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
};

export default B2becAddressForm;
