import { Col, Form, Input, Row } from 'antd';
import B2becButton from 'components/B2becButton';
import { useAsync } from 'hooks';
import { ASYNC_STATUS } from 'libs/constants';
import MaterialNumberInput from 'pages/Admin/PromotionPage/components/MaterialNumberInput';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductService } from 'services';

import styles from './ApplicableProducts.module.scss';
import { Tag } from './Tag';

const categoriesMapper = (data) => {
  return data.reduce((result, current) => {
    return { ...result, [current.categoryId]: current.categoryName };
  }, {});
};

const productsMapper = (data) => {
  return data.reduce((result, current) => {
    return { ...result, [current.materialNumber]: current.materialName };
  }, {});
};

const getInfo = (() => {
  return async (body, handler) => {
    try {
      const response = await handler(body);
      return Array.isArray(response.data) && response.data;
    } catch {
      // TODO
    }

    return Promise.reject();
  };
})();

const getCategoriesInfo = async (body) => {
  try {
    const response = await getInfo(body, ProductService.getCategoryInfo);
    if (response.length) {
      const data = categoriesMapper(response);
      return Promise.resolve(data);
    }
  } catch {
    return Promise.reject(new Error('invalidCategoryMessage'));
  }
};

const getProductsInfo = async (body) => {
  try {
    const response = await getInfo(body, ProductService.getProductInfo);
    const data = productsMapper(response);
    return Promise.resolve(data);
  } catch {
    return Promise.reject(new Error('invalidMaterialMessage'));
  }
};

const InputRender = ({ isMaterialInput, onChange, ...props }) => {
  if (isMaterialInput) {
    return (
      <MaterialNumberInput
        {...props}
        onChange={onChange}
        hasGreenTick={false}
      />
    );
  }

  return <Input {...props} onChange={(e) => onChange(e.target.value)} />;
};

const displayTag = (name, id) => {
  if (name) {
    return `${name} - ${id}`;
  }

  return id;
};

const ApplicableInput = ({ handleAdd, label, ...props }) => {
  const [inputValue, setInputValue] = useState('');
  const valueRef = useRef('');
  const { t } = useTranslation();
  const asyncHandler = useCallback(
    async (value) => {
      valueRef.current = value;
      await handleAdd(value);
      setInputValue('');
    },
    [handleAdd]
  );
  const { execute, status, error } = useAsync(asyncHandler, false);
  const errorMessage = valueRef.current === inputValue && error?.message;
  const validateStatus = !!errorMessage && 'error';
  const translatedMessage =
    !!errorMessage && t(`adminWorkspace.voucher.${errorMessage}`);
  const isDisabled = status === ASYNC_STATUS.PENDING;

  return (
    <Form.Item
      className="custom-form-item"
      validateStatus={validateStatus}
      help={translatedMessage}
    >
      <div className={styles.input}>
        <InputRender
          {...props}
          value={inputValue}
          onChange={(value) => {
            setInputValue(value);
          }}
          disabled={isDisabled}
        />
        <B2becButton onClick={() => execute(inputValue)} disabled={isDisabled}>
          {t('adminWorkspace.voucher.addApplicable')}
        </B2becButton>
      </div>
    </Form.Item>
  );
};

const Applicable = (props) => {
  const { title, placeHolder, value, handler, onChange, isMaterialInput } =
    props;

  const [items, setItems] = useState(value || []);
  const [extraData, setExtraData] = useState({});

  const handleGetInfo = useCallback(
    async (body) => {
      if (Array.isArray(body) && body.length) {
        const response = await handler(body);
        setExtraData((currenData) => Object.assign(response, currenData));
        setItems((currenItems) => {
          const newItems = body.filter((item) => !currenItems.includes(item));
          return [...currenItems, ...newItems];
        });
        return Promise.resolve(response);
      }
      return Promise.resolve({});
    },
    [handler]
  );

  const handleRemove = (key) => {
    const newItems = items.filter((item) => item !== key);
    setItems(newItems);
    onChange(newItems);
  };

  const handleAdd = async (inputValue) => {
    if (items.includes(inputValue)) {
      return undefined;
    }
    await handleGetInfo([inputValue]);
    onChange([...items, inputValue]);
    return undefined;
  };

  const ref = useRef();
  if (!ref.current) {
    ref.current = true;
    handleGetInfo(items);
  }

  return (
    <Form.Item
      className="custom-form-item"
      label={title}
      labelCol={{ span: 24 }}
    >
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <ApplicableInput
            handleAdd={handleAdd}
            placeholder={placeHolder}
            isMaterialInput={isMaterialInput}
          />
        </Col>
        {items.map((tag) => (
          <Col key={tag}>
            <Tag onRemove={() => handleRemove(tag)}>
              {displayTag(extraData[tag], tag)}
            </Tag>
          </Col>
        ))}
      </Row>
    </Form.Item>
  );
};

Applicable.propTypes = {
  title: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  onChange: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

export const ApplicableForm = React.memo(() => {
  const { t } = useTranslation();
  return (
    <>
      <Form.Item name="materialNumbers">
        <Applicable
          isMaterialInput
          key="materialNumbers"
          handler={getProductsInfo}
          title={t('adminWorkspace.voucher.applicableProducts')}
          placeHolder={t('adminWorkspace.voucher.applicableProducts')}
        />
      </Form.Item>
      <Form.Item
        name="categoryIds"
        normalize={(value) => {
          if (Array.isArray(value)) {
            return value.map((v) => Number.parseFloat(v));
          }
          return value;
        }}
      >
        <Applicable
          key="categoryIds"
          handler={getCategoriesInfo}
          title={t('adminWorkspace.voucher.applicableCategories')}
          placeHolder={t('adminWorkspace.voucher.applicableCategories')}
        />
      </Form.Item>
    </>
  );
});
