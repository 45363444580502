import { Checkbox } from 'antd';
import clsx from 'clsx';
import B2becActionButton from 'components/B2becActionButon';
import B2becTranslation from 'components/B2becTranslation';
import CustomButton from 'components/CustomButton';
import { EditIcon, InfoIcon } from 'components/Icons';
import { BUTTON_TYPE } from 'libs/constants';
import {
  CONFIG_ADDRESS_SCHEME,
  CONFIG_DATA_TYPES,
  CONFIG_LEVELS,
  CONFIG_LOGOS,
  CONFIG_NUMBERS_SEPARATOR,
} from 'libs/constants/configs';
import { formatDate, getFormatType } from 'libs/utils/formatDate';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

const useRenderColumns = (
  isFetching,
  onEditConfig,
  onResetConfig,
  form,
  shouldShowResetButton,
  canUpdateConfiguration,
  configs,
  scope,
  displayWarningModal,
  shouldDisplayWarningModal
) => {
  const { t } = useTranslation();
  const isGlobalScope = scope?.level === CONFIG_LEVELS.GLOBAL;

  const [selectedInfo, setSelectedInfo] = useState({
    showInfo: false,
    configName: undefined,
  });

  const infoPopupRef = useRef(null);

  const [editingConfig, setEditingConfig] = useState(null);

  const onClickOutside = (event) => {
    // onClick outside info button AND info popup
    if (infoPopupRef.current) {
      const infoCellElement = infoPopupRef.current.closest('td');
      if (!infoCellElement.contains(event.target)) {
        setSelectedInfo({
          showInfo: false,
          configName: undefined,
        });
      }
    }
  };

  const onSubmitRowHandler = useCallback(
    (record) => {
      if (!displayWarningModal) {
        setEditingConfig(null);
        onEditConfig(record);
      }
    },
    [onEditConfig, displayWarningModal]
  );

  const onEditRowHandler = useCallback(
    (record) => {
      // not render initial value for password record type
      const recordValue =
        (record?.dataType || '').toLowerCase() === CONFIG_DATA_TYPES.PASSWORD
          ? ''
          : record?.value;
      form.setFieldsValue({
        ...record,
        value: recordValue,
      });
      setEditingConfig(`${record?.name}:${record?.qualifier}`);
    },
    [form]
  );

  const onCancelEditRowHandler = useCallback(() => {
    setEditingConfig(null);
  }, []);

  const isEditingConfiguration = useCallback(
    (record = { name: '', qualifier: '' }) => {
      const { name, qualifier } = record;
      return editingConfig === `${name}:${qualifier}`;
    },
    [editingConfig]
  );

  const onValidateGlobalScope = useCallback(
    (record) => {
      if (isGlobalScope) {
        shouldDisplayWarningModal(true);
      }
      onEditRowHandler(record);
    },
    [shouldDisplayWarningModal, onEditRowHandler, isGlobalScope]
  );

  useEffect(() => {
    document.addEventListener('click', onClickOutside, false);
    return () => {
      document.removeEventListener('click', onClickOutside, false);
    };
  }, []);

  const onInfoCellClick = useCallback(
    (event, record) => {
      // onClick on info button or info popup
      if (
        !selectedInfo.showInfo ||
        (selectedInfo.showInfo && !infoPopupRef.current)
      ) {
        setSelectedInfo({
          showInfo: true,
          configName: record?.name,
        });
      } else if (infoPopupRef.current) {
        const newSelectedInfo = {};
        // onClick on different row OR (same row AND inside info popup)
        if (
          record?.name !== selectedInfo.configName ||
          (record?.name === selectedInfo.configName &&
            infoPopupRef.current.contains(event.target))
        ) {
          newSelectedInfo.showInfo = true;
          newSelectedInfo.configName = record?.name;
        } else {
          // onClick same row AND inside info button
          newSelectedInfo.showInfo = false;
          newSelectedInfo.configName = undefined;
        }
        setSelectedInfo(newSelectedInfo);
      }
    },
    [selectedInfo]
  );

  const renderInfoBtn = useCallback(
    (text, record) => {
      const props = {};
      let styles = { display: 'none' };
      if (selectedInfo.showInfo && selectedInfo.configName === record?.name) {
        props.ref = (ref) => {
          infoPopupRef.current = ref;
        };
        styles = { display: 'block' };
      }
      return (
        <div className="configuration-management__table__info__wrapper">
          <B2becActionButton>
            <InfoIcon />
          </B2becActionButton>
          <div
            {...props}
            className="configuration-management__table__info"
            style={{ ...styles }}
          >
            {text || 'N/A'}
          </div>
        </div>
      );
    },
    [selectedInfo]
  );

  const renderValue = useCallback(
    (value, record) => {
      const recordValue = (value || '').toString();
      if (record.name === 'Numbers_Separator') {
        return CONFIG_NUMBERS_SEPARATOR.find(
          (config) => config.value === recordValue
        ).label;
      }
      if (record.name === 'Address_scheme') {
        return CONFIG_ADDRESS_SCHEME.find(
          (config) => config.value === recordValue
        )?.label;
      }
      if (record.name === 'Logo') {
        return CONFIG_LOGOS.find((config) => config.value === recordValue)
          .label;
      }
      switch (record?.dataType?.toLowerCase()) {
        case CONFIG_DATA_TYPES.BOOLEAN:
          return (
            <>
              <Checkbox
                className="custom-input"
                checked={recordValue.toLowerCase() === 'true'}
                disabled
              />
              <span className="configuration-management__table__boolean-value">
                {recordValue.toLowerCase() === 'true'
                  ? t('configurationManagement.table.value.boolean.true')
                  : t('configurationManagement.table.value.boolean.false')}
              </span>
            </>
          );
        case CONFIG_DATA_TYPES.PASSWORD:
          return (
            <div className="configuration-management__table__password">
              <span>*****************</span>
            </div>
          );
        case CONFIG_DATA_TYPES.DATE:
          return `${formatDate(recordValue, getFormatType().dateTime)} HRS`;
        default:
          return recordValue;
      }
    },
    [t]
  );

  const columns = useMemo(
    () => [
      {
        dataIndex: 'description',
        width: '5%',
        editable: false,
        onCell: (record) => ({
          onClick: (event) => onInfoCellClick(event, record),
        }),
        render: (text, record) =>
          isFetching ? (
            <Skeleton />
          ) : (
            {
              props: {
                className: clsx({
                  'configuration-management__info--selected':
                    selectedInfo.showInfo &&
                    selectedInfo.configName === record?.name,
                }),
              },
              children: renderInfoBtn(text, record),
            }
          ),
      },
      {
        title: <B2becTranslation value="configurationManagement.table.name" />,
        dataIndex: 'name',
        ellipsis: true,
        width: '20%',
        editable: false,
        render: (text) => (isFetching ? <Skeleton /> : <span>{text}</span>),
      },
      {
        title: (
          <B2becTranslation value="configurationManagement.table.value.title" />
        ),
        dataIndex: 'value',
        ellipsis: true,
        width: '40%',
        editable: true,
        render: (text, record) =>
          isFetching ? <Skeleton /> : renderValue(text, record),
      },
      {
        title: (
          <B2becTranslation value="configurationManagement.table.parentInherited" />
        ),
        dataIndex: 'isInherit',
        width: '12%',
        align: 'center',
        render: (checked, record) =>
          isFetching ? (
            <Skeleton />
          ) : (
            !isEditingConfiguration(record) && (
              <Checkbox
                className="configuration-management__table__parent-inherited__checkbox custom-input"
                checked={checked}
                disabled
              />
            )
          ),
      },
      {
        dataIndex: '',
        width: '23%',
        render: (_, record) => {
          return (
            <div className="configuration-management__table__action-wrapper">
              {isFetching ? (
                <Skeleton />
              ) : (
                <>
                  {isEditingConfiguration(record) ? (
                    <div className="configuration-management__table__action-button">
                      <CustomButton
                        onClick={() => onCancelEditRowHandler()}
                        buttonSize="small"
                        type={BUTTON_TYPE.GHOST}
                      >
                        {t('buttonTexts.cancel')}
                      </CustomButton>
                      <CustomButton
                        onClick={() => onSubmitRowHandler(record)}
                        buttonSize="small"
                      >
                        {t('buttonTexts.save')}
                      </CustomButton>
                    </div>
                  ) : (
                    canUpdateConfiguration && (
                      <B2becActionButton
                        onClick={() => onValidateGlobalScope(record)}
                      >
                        <EditIcon />
                      </B2becActionButton>
                    )
                  )}
                  {shouldShowResetButton && (
                    <B2becActionButton onClick={() => onResetConfig(record)}>
                      <span>{t('buttonTexts.reset')}</span>
                    </B2becActionButton>
                  )}
                </>
              )}
            </div>
          );
        },
      },
    ],
    [
      t,
      onInfoCellClick,
      renderInfoBtn,
      renderValue,
      isFetching,
      onResetConfig,
      selectedInfo,
      isEditingConfiguration,
      onValidateGlobalScope,
      onSubmitRowHandler,
      onCancelEditRowHandler,
      shouldShowResetButton,
      canUpdateConfiguration,
    ]
  );

  const mergedColumns = useMemo(
    () =>
      (columns || []).map((column) => {
        if (!column?.editable) {
          return column;
        }

        return {
          ...column,
          onCell: (record) => ({
            record,
            dataIndex: column?.dataIndex,
            isEditing: isEditingConfiguration(record),
            dataType: record?.dataType?.toLowerCase(),
            form,
            configs,
          }),
        };
      }),
    [columns, isEditingConfiguration, form, configs]
  );

  return { columns: mergedColumns };
};

export default useRenderColumns;
