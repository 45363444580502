import './styles.scss';

import { useForm } from 'antd/lib/form/Form';
import B2becTranslation from 'components/B2becTranslation';
import { NotificationManager } from 'components/Notification';
import { useAsync } from 'hooks';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { RESPONSE_STATUS } from 'libs/constants/statuses';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { adminFapiVoucher } from 'services/fapi/adminFapi';

import VoucherPage from './components/Voucher';
import styles from './VoucherCreate.module.scss';

function VoucherCreate({ title, breadcrumbs, pageId }) {
  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);
  const [form] = useForm();
  const history = useHistory();

  useAdobeAnalysis(null, {
    pageId,
    pageName: 'Voucher Management',
    pageType: PAGE_TYPES.VOUCHER,
  });

  const { execute: executeCreateVoucherInfo } = useAsync(
    adminFapiVoucher.createVoucherInfo,
    false,
    () => {
      NotificationManager.success({
        message: 'notification.success.create',
      });

      history.push(linkGenerator('/voucher-management'));
    },
    (createVoucherInfoError) => {
      const { errorCode, detail } = createVoucherInfoError?.response?.data;
      const parsedDetail = JSON.parse(detail);
      if (
        errorCode === RESPONSE_STATUS.duplicateCode ||
        parsedDetail?.message?.includes('already')
      ) {
        form.setFields([
          {
            name: 'voucherCode',
            errors: [
              <B2becTranslation
                key="voucherCode"
                value="voucher.validations.voucherCodeNotDuplicate"
              />,
            ],
          },
        ]);
      } else {
        NotificationManager.error({
          message: 'notification.error.create',
        });
      }
    }
  );

  return (
    <div className={styles.voucherCreate}>
      <VoucherPage
        form={form}
        onSubmit={executeCreateVoucherInfo}
        voucherInfo={{ fromDate: null, toDate: null }}
      />
    </div>
  );
}

VoucherCreate.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default VoucherCreate;
