import { NotificationManager } from '../../../components/Notification';
import { ACCEPTED_UPLOAD_FILE_EXTENSIONS } from '../../../libs/constants';
import { fapiProductPortfolioService } from '../../../services/adminFapi';

export const importPorfolio = async (file, selectedScope, callback) => {
  const isCSV = ACCEPTED_UPLOAD_FILE_EXTENSIONS?.includes(file?.type);

  if (!isCSV) {
    NotificationManager.error({
      message: 'notification.upload.csvOnly',
    });
    return;
  }

  try {
    const formData = new FormData();
    formData.append('FormFile', file);

    await fapiProductPortfolioService.importFile(formData, selectedScope);

    NotificationManager.success({
      message: 'notification.success.upload',
    });
    callback();
  } catch (e) {
    NotificationManager.error({
      message: 'notification.error.upload',
      variables: { type: 'porfolio' },
    });
  }
};

export const exportPorfolio = async (customerNumber) => {
  try {
    await fapiProductPortfolioService.exportFile(customerNumber);

    NotificationManager.success({
      message: 'notification.success.export',
      variables: { type: 'porfolio' },
    });
  } catch (error) {
    NotificationManager.error({
      message: 'notification.error.export',
      variables: { type: 'porfolio' },
    });
  }
};
