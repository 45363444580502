import { Form } from 'antd';
import B2becInfoLayout from 'components/B2becInfoLayout';
import { NotificationManager } from 'components/Notification';
import ScopeSelection from 'components/ScopeSelection';
import useAsync from 'hooks/useAsync';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useDocumentTitle from 'hooks/useDocumentTitle';
import useDummyItems from 'hooks/useDummyItems';
import usePermission from 'hooks/usePermission';
import { ASYNC_STATUS, STATUS_CODE } from 'libs/constants';
import {
  CONFIG_GROUP_TYPE_ALL,
  CONFIG_LEVELS,
  DEFAULT_CONFIG_SCOPE,
} from 'libs/constants/configs';
import { MODULE_ADMINISTRATION_RIGHTS } from 'libs/constants/modulerights';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { adminFapiConfiguration } from 'services/adminFapi';
import { getCurrencyConfigSuccess } from 'store/slices/configSlice';

import ConfigurationTable from './components/ConfigurationTable';
import GroupFilter from './components/GroupFilter';
import styles from './ConfigurationPage.module.scss';

const configGroupShape = {
  key: '',
  name: 'N/A',
};

function ConfigurationPage(props) {
  const { title, breadcrumbs } = props;

  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [configs, setConfigs] = useState([]);
  const dummyGroups = useDummyItems(2, configGroupShape, 'key');
  const [groups, setGroups] = useState([CONFIG_GROUP_TYPE_ALL, ...dummyGroups]);
  const [selectedGroup, setSelectedGroup] = useState(
    CONFIG_GROUP_TYPE_ALL.name
  );
  const [selectedScope, setSelectedScope] = useState(DEFAULT_CONFIG_SCOPE);
  const initLoading = useRef(false);
  const {
    verifiedPermissions: [canResetConfiguration, canUpdateConfiguration],
  } = usePermission([
    MODULE_ADMINISTRATION_RIGHTS.RESET_CONFIGURATION,
    MODULE_ADMINISTRATION_RIGHTS.UPDATE_CONFIGURATION,
  ]);

  const shouldShowResetButton =
    selectedScope.level !== CONFIG_LEVELS.GLOBAL && canResetConfiguration;
  const getAllConfigs = useCallback(async (scope = DEFAULT_CONFIG_SCOPE) => {
    try {
      const response = await adminFapiConfiguration.getAllConfigs(
        scope,
        1,
        100
      );

      console.log('response.data', response.data);
      return response.data.items;
    } catch {
      return [];
    }
  }, []);

  const getAllGroups = useCallback(
    () => adminFapiConfiguration.getAllGroups(),
    []
  );

  const onUpdateSuccess = useCallback(
    (name, value) => {
      if (['currency_symbol', 'currency_position_front'].includes(name)) {
        dispatch(getCurrencyConfigSuccess({ [name]: value }));
      }
    },
    [dispatch]
  );

  const updateConfig = useCallback(
    async (name, value) => {
      try {
        const response = await adminFapiConfiguration.updateConfig({
          name,
          value,
          ...selectedScope,
        });
        onUpdateSuccess(name, value);
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    [onUpdateSuccess, selectedScope]
  );

  const onSuccess = useCallback((response) => {
    setConfigs(response);
  }, []);

  const onError = useCallback((error) => {
    NotificationManager.error({
      message: 'notification.error.load',
      description: error?.data?.message,
    });
  }, []);

  const onGetGroupsSuccess = useCallback((response) => {
    const { status, data } = response || {};
    if (status === STATUS_CODE.SUCCESS) {
      const orderedGroups = data.sort();
      const groupsToShow = orderedGroups.map((group) => ({
        key: group,
        name: group,
      }));

      groupsToShow.unshift(CONFIG_GROUP_TYPE_ALL);
      setGroups(groupsToShow || []);
    }
  }, []);

  const onGetGroupsError = useCallback((error) => {
    setGroups([CONFIG_GROUP_TYPE_ALL]);
    NotificationManager.error({
      message: 'notification.error.load',
      description: error?.data?.message,
    });
  }, []);

  const { execute: executeGetAllConfigs, status: getAllConfigsStatus } =
    useAsync(getAllConfigs, false, onSuccess, onError);

  const configsByGroup = useMemo(() => {
    if (selectedGroup !== CONFIG_GROUP_TYPE_ALL.key) {
      return configs?.filter((config) => config.group === selectedGroup);
    }
    return configs;
  }, [configs, selectedGroup]);

  useEffect(() => {
    if (!initLoading.current) {
      initLoading.current = true;
      executeGetAllConfigs();
    }
  }, [executeGetAllConfigs]);

  const { status: getGroupsStatus } = useAsync(
    getAllGroups,
    true,
    onGetGroupsSuccess,
    onGetGroupsError
  );

  const { execute: executeConfigUpdate, status: updateConfigStatus } = useAsync(
    updateConfig,
    false
  );

  const resetConfig = useCallback(
    (record) => {
      const { level, countryId, customerNumber } = selectedScope;
      const { name } = record;
      const params = {
        name,
        level,
        qualifier: undefined,
        countryId: undefined,
      };

      switch (level) {
        case CONFIG_LEVELS.COUNTRY:
          params.countryId = countryId;
          break;
        case CONFIG_LEVELS.CUSTOMER:
          params.countryId = countryId;
          params.qualifier = customerNumber;
          break;
        default:
      }

      return adminFapiConfiguration.resetConfig({
        ...params,
      });
    },
    [selectedScope]
  );

  const handleResetConfigSuccess = useCallback(
    (response) => {
      if (response?.data) {
        NotificationManager.success({
          message: 'notification.success.configuration.reset',
        });

        executeGetAllConfigs(selectedScope);
      }
    },
    [executeGetAllConfigs, selectedScope]
  );

  const handleResetConfigError = useCallback((error) => {
    NotificationManager.error({
      message: 'notification.error.configuration.reset',
      description: error?.message,
    });
  }, []);

  const { execute: executeResetConfig, status: resetConfigStatus } = useAsync(
    resetConfig,
    false,
    handleResetConfigSuccess,
    handleResetConfigError
  );

  const handleResetConfig = useCallback(
    (record) => executeResetConfig(record),
    [executeResetConfig]
  );

  const isFetching =
    getAllConfigsStatus === ASYNC_STATUS.PENDING ||
    updateConfigStatus === ASYNC_STATUS.PENDING ||
    resetConfigStatus === ASYNC_STATUS.PENDING;

  const isFetchingGroups = getGroupsStatus === ASYNC_STATUS.PENDING;

  const onSelectGroup = useCallback((name) => {
    setSelectedGroup(name);
  }, []);

  const updateConfigList = useCallback(
    (configName, configValue) => {
      // update the configuration list after updated a row
      const newConfigs = [...configs];
      const configIndex = (configs || []).findIndex(
        (config) => config?.name === configName
      );

      if (configIndex > -1) {
        const updatedConfig = newConfigs[configIndex];
        newConfigs.splice(configIndex, 1, {
          ...updatedConfig,
          ...configValue,
        });
        setConfigs(newConfigs);
      }
    },
    [configs]
  );

  const onEditConfig = useCallback(
    async (record) => {
      const configName = record?.name;
      const configValue = await form.validateFields();

      executeConfigUpdate(configName, configValue?.value).then(
        ({ response, error }) => {
          if (response?.data) {
            NotificationManager.success({
              message: 'notification.success.configuration.update',
            });
            // update the configuration list after submitting the changes
            updateConfigList(configName, configValue);
          }
          if (error) {
            NotificationManager.error({
              message: 'notification.error.configuration.update',
              description: error?.response?.data,
            });
          }
        }
      );
    },
    [form, executeConfigUpdate, updateConfigList]
  );

  const onSelectScope = useCallback(
    (newScope) => {
      setSelectedScope(newScope);
      executeGetAllConfigs(newScope);
    },
    [executeGetAllConfigs]
  );

  return (
    <B2becInfoLayout className={styles.ConfigurationManagement}>
      <B2becInfoLayout.Title title={t('pageTitle.configurations')}>
        <div className={styles.TopBar}>
          <GroupFilter
            isLoading={isFetchingGroups}
            groups={groups}
            onSelectGroup={onSelectGroup}
          />
          <ScopeSelection onSelectScope={onSelectScope} />
        </div>
      </B2becInfoLayout.Title>
      <B2becInfoLayout.Content>
        <ConfigurationTable
          key={selectedGroup}
          isFetching={isFetching}
          configs={configsByGroup}
          onEditConfig={onEditConfig}
          onResetConfig={handleResetConfig}
          form={form}
          shouldShowResetButton={shouldShowResetButton}
          canUpdateConfiguration={canUpdateConfiguration}
          scope={selectedScope}
          isShowSearch={selectedGroup === CONFIG_GROUP_TYPE_ALL.key}
        />
      </B2becInfoLayout.Content>
    </B2becInfoLayout>
  );
}

ConfigurationPage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
};

ConfigurationPage.defaultProps = {};

export default ConfigurationPage;
