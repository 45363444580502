import { CONFIG_NAMES } from 'libs/constants/configs';
import { MODULE_USER_MANAGEMENT_RIGHTS } from 'libs/constants/modulerights';
import { USER_STATUS } from 'libs/constants/user';
import { logErrors } from 'libs/utils/common';
import { isCurrentTimeInSAPDowntime } from 'libs/utils/dateTimeUtils';
import { handleChangeLanguage } from 'libs/utils/language';
import { isEmptyObject } from 'libs/utils/object';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { setupService } from 'services';
import {
  getSubmenuProductCategoryListError,
  getSubmenuProductCategoryListSuccess,
} from 'store/slices/categorySlice';
import {
  getCountryNames,
  getSAPDowntimeSuccess,
  getUserConfigurationsFailed,
  getUserConfigurationsSuccess,
} from 'store/slices/configSlice';
import { customerActions } from 'store/slices/customerSlice';
import { getAssignableRolesByUserId } from 'store/slices/roleSlice';
import { setupActions } from 'store/slices/setupSlice';
import { userActions } from 'store/slices/userSlice';

import mapConfigData from './apiDataMapping/configMapping';

function removeFaultyProductCategory(data) {
  return data?.categories?.filter(
    (category) => isEmptyObject(category) === false
  );
}

function* handleSetupDataForNavigationMenu(navigationMenu) {
  try {
    const cleanedNavigationMenu = removeFaultyProductCategory(navigationMenu);

    yield put(getSubmenuProductCategoryListSuccess(cleanedNavigationMenu));
  } catch (error) {
    yield put(getSubmenuProductCategoryListError());
    logErrors(error);
  }
}

function* handleSetupDataForUserDetails(userDetail) {
  try {
    const userInfo = userDetail?.userInfo;
    handleChangeLanguage(userInfo?.language, userInfo?.countryId);
    yield put(userActions.getUserDetailsSucceeded(userDetail));
  } catch (error) {
    yield put(userActions.getUserDetailsFailed());
    logErrors(error);
  }
}

function* handleSetupDataForConfigurations(configurations) {
  try {
    const mappedConfigs = mapConfigData(configurations);
    const {
      [CONFIG_NAMES.SAP_Downtime_Start_Time]: startTime,
      [CONFIG_NAMES.SAP_Downtime_End_Time]: endTime,
      ...otherConfigs
    } = mappedConfigs;
    const isInSAPDowntime = isCurrentTimeInSAPDowntime(startTime, endTime);
    const parsedServiceRepairPage = JSON.parse(
      otherConfigs[CONFIG_NAMES.SERVICE_REPAIR_PAGE]
    );
    const parsedAvailableLanguages = JSON.parse(
      otherConfigs[CONFIG_NAMES.AVAILABLE_LANGUAGES]
    );
    const parsedAvailableSalesOrgs = JSON.parse(
      otherConfigs[CONFIG_NAMES.AVAILABLE_SALESORG]
    );

    otherConfigs[CONFIG_NAMES.SERVICE_REPAIR_PAGE] = parsedServiceRepairPage;
    otherConfigs[CONFIG_NAMES.AVAILABLE_LANGUAGES] = parsedAvailableLanguages;
    otherConfigs[CONFIG_NAMES.AVAILABLE_SALESORG] = parsedAvailableSalesOrgs;

    yield all([
      put(getUserConfigurationsSuccess(otherConfigs)),
      put(getSAPDowntimeSuccess(isInSAPDowntime)),
    ]);
  } catch (error) {
    yield put(getUserConfigurationsFailed());
    logErrors(error);
  }
}

function isUserAuthorized(userDetail) {
  return userDetail?.userInfo?.status?.toLowerCase() === USER_STATUS.AUTHORIZED;
}

function* checkUserHasPermissionToAssignRoles() {
  const rights = yield select((state) => state.role.rightList);
  const setRights = new Set(rights);

  return setRights.has(MODULE_USER_MANAGEMENT_RIGHTS.UPDATE_USER);
}

function* fetchSetupDataSaga() {
  try {
    const { data } = yield call(setupService.getSetupData);

    const userDetail = data?.userDetail;
    yield call(handleSetupDataForUserDetails, userDetail);

    const hasPermissionToAssignRoles = yield call(
      checkUserHasPermissionToAssignRoles
    );

    if (isUserAuthorized(userDetail)) {
      const effects = [
        put(customerActions.getCustomerInfo()),
        put(getCountryNames()),
        call(handleSetupDataForNavigationMenu, data?.navigationMenu),
        call(handleSetupDataForConfigurations, data?.configurations),
      ];

      if (hasPermissionToAssignRoles) {
        effects.push(
          put(getAssignableRolesByUserId(userDetail?.userInfo?.userId))
        );
      }

      yield all(effects);
    }

    yield put(setupActions.fetchSetupDataSuccess());
  } catch ({ response }) {
    const { data } = response;

    // Check if the error is due to missing data
    if (data.errorCode === '50001') {
      yield put(setupActions.showMissingDataUserModal());
    }

    yield put(setupActions.fetchSetupDataFailure());
  }
}

export default function* setupSaga() {
  yield takeLatest(setupActions.fetchSetupData.type, fetchSetupDataSaga);
}
