import './styles.scss';

import { Col, Form, Row } from 'antd';
import B2becCustomSelect from 'components/B2becCustomSelect';
import B2becInfoLayout from 'components/B2becInfoLayout';
import B2becSpinner from 'components/B2becSpinner';
import { CustomFormItem } from 'components/B2bUserForm';
import CustomButton from 'components/CustomButton';
import { B2becButtonBack } from 'components/CustomButton/B2becButtonIcon';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { EMPLOYEE_USER, USER_ACCOUNT_STATUS } from 'libs/constants/user';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  addEmployeeUser,
  getEmployeeAvailableLanguages,
  selectEmployeeAvailableLanguages,
  selectIsAddingUser,
} from 'store/slices/usersManagementSlice';

import UserStatusCheckbox from '../InviteNewUserPage/components/UserStatusCheckbox';
import SuggestionInput from './SuggestionInput';

const AddEmployeeUserPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();

  const availableLanguages = useSelector(selectEmployeeAvailableLanguages);
  const isLoading = useSelector(selectIsAddingUser);

  const languageOptions = useMemo(
    () =>
      availableLanguages.map((language) => ({
        label: language?.name,
        value: language?.value,
      })),
    [availableLanguages]
  );

  const onSubmit = ({ userInformation, userStatus, language }) => {
    const mappedValue = {
      userId: userInformation,
      userStatus,
      language,
    };

    dispatch(addEmployeeUser(mappedValue));
  };

  const onSetUserIdValue = useCallback(
    (userId) => {
      form.setFieldsValue({
        userInformation: userId,
      });
      // get available languages by employee user id
      dispatch(getEmployeeAvailableLanguages(userId));
    },
    [form, dispatch]
  );

  const onBackHandler = () => {
    history.push(linkGenerator('/user-management/employee'));
  };

  return (
    <>
      <div>
        <div className="mandatory-text">{t('form.note.mandatory')}</div>
        <Form
          layout="vertical"
          className="b2b-user-form"
          form={form}
          initialValues={{
            userStatus: USER_ACCOUNT_STATUS.AUTHORIZED,
          }}
          onFinish={onSubmit}
        >
          <Row>
            <Col span={18}>
              <CustomFormItem
                name="userInformation"
                rules={[{ required: true }]}
              >
                <SuggestionInput onSetUserIdValue={onSetUserIdValue} />
              </CustomFormItem>
            </Col>
            <Col span={18}>
              <CustomFormItem name="userStatus" rules={[{ required: true }]}>
                <UserStatusCheckbox isDisabled />
              </CustomFormItem>
            </Col>
            <Col span={9}>
              <CustomFormItem name="language" rules={[{ required: true }]}>
                <B2becCustomSelect
                  options={languageOptions}
                  loading={isLoading}
                  disabled={isLoading}
                />
              </CustomFormItem>
            </Col>
          </Row>
          <Row>
            <Col span={9}>
              <CustomButton type="ghost" onClick={onBackHandler}>
                {t('buttonTexts.cancel')}
              </CustomButton>
            </Col>
            <Col span={9}>
              <CustomButton style={{ float: 'right' }} htmlType="submit">
                {t('buttonTexts.addNewUser')}
              </CustomButton>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

const AddEmployeeUserPageWithLayout = (props) => {
  const { title, breadcrumbs, pageId } = props;
  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);

  const isAddingEmployee = useSelector(selectIsAddingUser);

  useAdobeAnalysis(null, {
    pageId,
    pageType: PAGE_TYPES.USER_MANAGEMENT,
    pageName: 'add employee user page',
  });

  const history = useHistory();

  const { t } = useTranslation();

  const onBackHandler = useCallback(() => {
    history.push(linkGenerator(`/user-management/${EMPLOYEE_USER}`));
  }, [history]);

  return (
    <B2becInfoLayout>
      <B2becSpinner isLoading={isAddingEmployee} />
      <B2becInfoLayout.Title title={t('pageTitle.addUser')}>
        <B2becButtonBack buttonSize="small" onClick={onBackHandler}>
          {t('buttonTexts.backToOverview')}
        </B2becButtonBack>
      </B2becInfoLayout.Title>
      <B2becInfoLayout.Content>
        <AddEmployeeUserPage />
      </B2becInfoLayout.Content>
    </B2becInfoLayout>
  );
};

AddEmployeeUserPageWithLayout.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default AddEmployeeUserPageWithLayout;
