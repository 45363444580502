import './styles.scss';

import { Divider } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import ConfirmModal from 'components/ConfirmModal';
import CustomButton from 'components/CustomButton';
import { NotificationManager } from 'components/Notification';
import PermissionWrapper from 'HOCs/permissionWrapper';
import { useAsync } from 'hooks';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { ASYNC_STATUS } from 'libs/constants';
import { VOUCHER_ACTION_TYPES } from 'libs/constants/actionTypes';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { MODULE_PROMOTION_VOUCHER_ADMINISTRATION_RIGHTS } from 'libs/constants/modulerights';
import { RESPONSE_STATUS } from 'libs/constants/statuses';
import { convertDateToMoment } from 'libs/utils/formatDate';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { adminFapiVoucher } from 'services/fapi/adminFapi';
import { voucherDataSelector } from 'store/selectors/voucherSelectors';

import VoucherPage from './components/Voucher';
import styles from './VoucherCreate.module.scss';

function VoucherEdit({ title, breadcrumbs, pageId }) {
  const { voucherId } = useParams();
  const { t } = useTranslation();
  const [form] = useForm();
  const [isShowConfirmation, setIsShowConfirmation] = useState(false);
  const history = useHistory();
  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs, {
    titleData: [voucherId],
    pathData: [voucherId],
  });

  useAdobeAnalysis(null, {
    pageId,
    pageName: 'Voucher Management',
    pageType: PAGE_TYPES.VOUCHER,
  });

  const { voucherList } = useSelector(voucherDataSelector);

  const voucherData = useMemo(() => {
    if (Array.isArray(voucherList)) {
      const data = voucherList.find((voucher) => voucher.id === voucherId);
      if (data) {
        return {
          ...data,
          fromDate: convertDateToMoment(data.startDate),
          toDate: convertDateToMoment(data.endDate),
          fromTime: convertDateToMoment(data.startDate),
          toTime: convertDateToMoment(data.endDate),
        };
      }
    }

    return undefined;
  }, [voucherId, voucherList]);

  const updateVoucherHandler = useCallback(
    (updatedValue) => {
      return adminFapiVoucher.updateVoucherInfo(voucherId, updatedValue);
    },
    [voucherId]
  );

  const deleteVoucherHandler = useCallback(async () => {
    try {
      await adminFapiVoucher.deleteVoucherInfo(voucherId);
      NotificationManager.success({
        message: 'notification.success.delete',
      });
      history.push(linkGenerator('/voucher-management'));
    } catch {
      NotificationManager.error({
        message: 'notification.error.delete',
      });
    }
  }, [history, voucherId]);

  const { execute: executeUpdateVoucherInfo, status: updateVoucherInfoStatus } =
    useAsync(
      updateVoucherHandler,
      false,
      () => {
        NotificationManager.success({
          message: 'notification.success.update',
        });
      },
      (updateVoucherInfoError) => {
        const { errorCode, detail } = updateVoucherInfoError?.response?.data;
        const parsedDetail = JSON.parse(detail);
        if (
          errorCode === RESPONSE_STATUS.duplicateCode ||
          parsedDetail?.message?.includes('already')
        ) {
          NotificationManager.error({
            message: 'voucher.validationMessage.voucherCodeAlreadyExists',
          });
        } else {
          NotificationManager.error({
            message: 'notification.error.update',
          });
        }
      }
    );

  if (!voucherData) {
    return <Redirect to={linkGenerator('/voucher-management')} />;
  }

  return (
    <div className={styles.voucherCreate} key={voucherData.id}>
      <VoucherPage
        voucherInfo={voucherData}
        voucherAction={VOUCHER_ACTION_TYPES.EDIT_VOUCHER}
        onSubmit={executeUpdateVoucherInfo}
        form={form}
        disabled={updateVoucherInfoStatus === ASYNC_STATUS.PENDING}
      />
      <Divider />
      <PermissionWrapper
        permission={
          MODULE_PROMOTION_VOUCHER_ADMINISTRATION_RIGHTS.DELETE_VOUCHER
        }
      >
        <ConfirmModal
          isShowModal={isShowConfirmation}
          setIsShowModal={setIsShowConfirmation}
          modalTitle={t('voucher.deleteVoucher')}
          modalContent={t('voucher.deleteVoucherConfirm')}
          confirmActionHandler={deleteVoucherHandler}
        />
        <div className="delete-voucher">
          <h3>{t('voucher.deleteVoucher')}</h3>
          <p>{t('voucher.deleteVoucherNote')}</p>
        </div>

        <div className="voucher__footer">
          <CustomButton
            className="btn btn--submit mt-6"
            type="primary"
            onClick={() => setIsShowConfirmation(true)}
          >
            {t('voucher.deleteButton')}
          </CustomButton>
        </div>
      </PermissionWrapper>
    </div>
  );
}

VoucherEdit.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default VoucherEdit;
