import { nanoid } from '@reduxjs/toolkit';
import { NotificationManager } from 'components/Notification';
import { STATUS_CODE } from 'libs/constants';
import { RESPONSE_STATUS } from 'libs/constants/statuses';
import { USER_TYPES } from 'libs/constants/user';
import { linkGenerator } from 'libs/utils/language';
import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import {
  adminFapiUserManagement,
  fapiEmployeeService,
} from 'services/adminFapi';

import {
  addEmployeeUser,
  addEmployeeUserFailed,
  addEmployeeUserSuccess,
  getEmployeeAvailableLanguages,
  getEmployeeAvailableLanguagesFailed,
  getEmployeeAvailableLanguagesSuccess,
  getSuggestionList,
  getSuggestionListFailed,
  getSuggestionListSuccess,
  getUserOverviewList,
  getUserOverviewListFailed,
  getUserOverviewListSuccess,
} from '../slices/usersManagementSlice';

function* getUserOverviewListSaga({ payload }) {
  try {
    const { data } = yield adminFapiUserManagement.getUserList(payload);
    const { items } = data;

    // Add key to each user to prevent empty users
    // that leads to warning of duplicated row key in table
    const transformedItems = items.map((item) => ({
      ...item,
      key: nanoid(),
      sapCustomerNumber:
        item?.userType === 'T' ? item?.customerNumber : item?.sapCustomerNumber,
    }));

    const transformedData = { ...data, items: transformedItems };

    yield put(getUserOverviewListSuccess(transformedData));
  } catch (error) {
    yield put(getUserOverviewListFailed());
    NotificationManager.error({
      message: 'notification.error.getUserList',
      description: error?.response?.data,
    });
  }
}

function* getEmployeeSuggestionListSaga({ payload }) {
  try {
    const { data, status } =
      yield fapiEmployeeService.getEmployeeSuggestionList(payload);
    if (status === STATUS_CODE.SUCCESS) {
      yield put(getSuggestionListSuccess(data));
    }
  } catch (error) {
    yield put(getSuggestionListFailed(error?.response?.data));
  }
}

function* getEmployeeAvailableLanguagesSaga({ payload }) {
  try {
    const { data } = yield call(
      fapiEmployeeService.getEmployeeUserAvailableLanguages,
      payload
    );
    const formattedData = JSON.parse(data);

    yield put(getEmployeeAvailableLanguagesSuccess(formattedData));
  } catch (error) {
    yield put(getEmployeeAvailableLanguagesFailed(error?.response?.data));
  }
}

function* addEmployeeUserSaga({ payload }) {
  try {
    const { history } = yield getContext('dependencies');
    const { data, status } = yield call(
      fapiEmployeeService.addEmployeeUser,
      payload
    );

    if (status === STATUS_CODE.SUCCESS) {
      yield put(addEmployeeUserSuccess(data));
      NotificationManager.success({
        message: 'notification.success.addEmployeeUser',
      });
      history.push(
        linkGenerator(`/user-management/${USER_TYPES.EMPLOYEE_USER}`)
      );
    }
  } catch (error) {
    const { errorCode, detail } = error?.response?.data;
    const parsedDetail = JSON.parse(detail);

    if (
      errorCode === RESPONSE_STATUS.duplicateCode ||
      parsedDetail?.message?.includes('exists')
    ) {
      NotificationManager.error({
        message: 'notification.error.addEmployeeUser',
        description: 'notification.error.userAlreadyExists',
      });
    } else {
      NotificationManager.error({
        message: 'notification.error.addEmployeeUser',
      });
    }

    yield put(addEmployeeUserFailed());
  }
}

export default function* userSaga() {
  yield takeLatest(getSuggestionList.type, getEmployeeSuggestionListSaga);
  yield takeLatest(
    getEmployeeAvailableLanguages.type,
    getEmployeeAvailableLanguagesSaga
  );
  yield takeLatest(addEmployeeUser.type, addEmployeeUserSaga);
  yield takeLatest(getUserOverviewList.type, getUserOverviewListSaga);
}
