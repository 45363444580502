import { useSelector } from 'react-redux';
import { selectCountryNames } from 'store/selectors/configSelector';

function useCountryName(countryName) {
  const countryNames = useSelector(selectCountryNames);

  return countryNames.find((country) => country?.value === countryName)?.name;
}

export default useCountryName;
