import { Button, Col, Input, Row } from 'antd';
import { ReactComponent as AlertIconSVG } from 'assets/icons/alerticon.svg';
import greenCheckIconImage from 'assets/img/green-check-icon.webp';
import moreInfoIconImage from 'assets/img/more-info-icon.webp';
import HowToFindItemNumber from 'components/HowToFindItemNumber';
import ImageWithFallBack from 'components/ImageWithFallback';
import { useDebounce } from 'hooks';
import { ASYNC_STATUS, isValidMaterial } from 'libs/constants';
import { formatMaterialID } from 'libs/utils/material-number';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DisisHomeButton from './DisisHomeButton';
import styles from './SparePartsFinderInput.module.scss';
import SparePartsProductInfo from './SparePartsProductInfo';

const SparePartsFinderInput = () => {
  const { t } = useTranslation();
  const [itemNumber, setItemNumber] = useState('');
  const [isItemNumberInfoShown, setIsItemNumberInfoShown] = useState(false);
  const [iconState, setIconState] = useState(ASYNC_STATUS.IDLE);

  const debouncedItemNumber = useDebounce(itemNumber, 500);

  const handleSetIconState = useCallback((state) => {
    setIconState(state);
  }, []);

  const handleInputValue = (str) => {
    if (str.length <= 8 && RegExp('[0-9]{8}').test(str)) {
      const formattedStr = formatMaterialID(str);
      return formattedStr;
    }
    return str;
  };

  const handleChange = (e) => {
    const value = e.target.value.trim();
    const re = /^[\d, \\., -]+$/;
    if (value !== '' && re.test(value)) {
      const formattedValue = handleInputValue(value);
      setItemNumber(formattedValue);
    } else {
      setItemNumber(value.replace(/[^\d, \\., -]/, ''));
    }
    setIconState(ASYNC_STATUS.IDLE);
  };

  const toggleInfoShow = () => {
    setIsItemNumberInfoShown(!isItemNumberInfoShown);
  };

  const renderValidationIcon = () => {
    if (iconState === ASYNC_STATUS.ERROR && isValidMaterial(itemNumber)) {
      return (
        <div className={styles.error}>
          <AlertIconSVG />
        </div>
      );
    }

    if (iconState === ASYNC_STATUS.SUCCESS) {
      return (
        <div className={styles.greenCheck}>
          <ImageWithFallBack src={greenCheckIconImage} />
        </div>
      );
    }

    return <></>;
  };

  return (
    <div className={styles.sparePartsFinderInput}>
      <Row gutter={[100, 0]}>
        <Col lg={10} xs={24}>
          <span className={styles.label}>
            {t('service.selectMachine.itemNumber.label')}
          </span>
          <div className={styles.wrapper}>
            <Input
              onChange={handleChange}
              placeholder={t('service.selectMachine.itemNumber.placeHolder')}
              className="custom-input"
              autoComplete="off"
              value={itemNumber}
            />
            {renderValidationIcon()}
            <Button className={styles.infoButton} onClick={toggleInfoShow}>
              <ImageWithFallBack src={moreInfoIconImage} />
            </Button>
          </div>
          {debouncedItemNumber !== '' && debouncedItemNumber === itemNumber && (
            <SparePartsProductInfo
              key={debouncedItemNumber}
              materialNumber={debouncedItemNumber}
              handleSetIconState={handleSetIconState}
            />
          )}
          <DisisHomeButton />
        </Col>
        <Col lg={14} xs={24}>
          <HowToFindItemNumber
            visible={isItemNumberInfoShown}
            onToggle={toggleInfoShow}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SparePartsFinderInput;
