import B2becCustomSelect from 'components/B2becCustomSelect';
import { mergeIntoStringWithSeparator } from 'libs/utils/array';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectCountryNames } from 'store/selectors/configSelector';

const AddressSelect = (props) => {
  const { deliveryAddresses, placeholder, onChange } = props;

  const countryNames = useSelector(selectCountryNames);

  const mergeAddress = useCallback(
    (address) => {
      const {
        partnerNumber = '',
        name = '',
        name2 = '',
        name3 = '',
        street = '',
        houseNumber = '',
        street4 = '',
        zip = '',
        city = '',
        country = '',
      } = address;

      const foundConfigForName = countryNames.find(
        (countryNameConfig) => countryNameConfig.value === country
      );
      const countryName = foundConfigForName ? foundConfigForName.name : '';

      return mergeIntoStringWithSeparator()(
        partnerNumber,
        name,
        name2,
        name3,
        street,
        houseNumber,
        street4,
        zip,
        city,
        countryName
      );
    },
    [countryNames]
  );

  const addressArray = useMemo(() => {
    const initialArray = [
      {
        key: 0,
        value: 0,
        label: placeholder,
      },
    ];

    return (deliveryAddresses || []).reduce((arr, address, index) => {
      arr.push({
        key: index + 1,
        value: index + 1,
        label: mergeAddress(address),
      });
      return arr;
    }, initialArray);
  }, [deliveryAddresses, placeholder, mergeAddress]);

  const handleChange = useCallback(
    (selectedId) => {
      const selected = deliveryAddresses[selectedId - 1];
      onChange(selected, selectedId);
    },
    [deliveryAddresses, onChange]
  );

  return (
    <B2becCustomSelect
      onClick={(e) => {
        e.preventDefault();
      }}
      style={{ width: '100%' }}
      options={addressArray}
      placeholder={placeholder}
      onChange={handleChange}
    />
  );
};

export default AddressSelect;
